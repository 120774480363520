import Header from '../../components/Header/Header';
import Posts from '../../components/Posts/Posts';
import Sidebar from '../../components/sidebar/Sidebar';
import'./home.css';

function Home() {
  return (
    <>
    <Header/>
    <div className="home">
    <Posts/>
    <Sidebar/>
    </div>
    </>
  )
}

export default Home
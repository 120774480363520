
import Topbar from './components/Topbar/Topbar'
import Login from './pages/login/Login'
import Register from './pages/register/Register'
import Settings from './pages/settings/Settings'
import Single from './pages/single/Single'
import Write from './pages/Write/Write'
import Home from './pages/home/Home'
import {BrowserRouter as Router,Switch,Route,} from 'react-router-dom';


function App() {
  const user =true;
  return (
    <Router>
    <Topbar/>
    <Switch>
      <Route path='/'>
        <Home/>
        </Route>
      <Route path='Register'> {user ? <Home/> :<Register/>}</Route>
      <Route path='Login'>{user ? <Home/> :<Login/>} </Route>
      <Route path='Login'>{user ? <Write/> :<Register/>} </Route>
      <Route path='Login'>{user ? <Settings/> :<Register/>} </Route>
        <Route path='post/:postId'>
        <Single/>
      </Route>
    </Switch>
    </Router>
  )
}

export default App
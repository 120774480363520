import './Header.css';

function Header() {
  return (
    <div className='Header'>
        <div className="headerTitles">
        <span className="headerTitleSm">MASTER'S CARE INTERNATIONAL INITIATIVE</span>
          <span className="headerTitleLg">Blog</span>
          
        </div>
        <img
        className='headerImg'
         src="https://th.bing.com/th/id/R.282d5496804870c1a19faf1c481ce355?rik=mZVOijEi86vtow&riu=http%3a%2f%2fjdh.tldsb.on.ca%2fwp-content%2fuploads%2fsites%2f18%2f2016%2f03%2fgraduation.png&ehk=%2fq4P24i2Cf2MhFT%2b8CbnvmgD6v5kXtOoriG2NcNPzo8%3d&risl=&pid=ImgRaw&r=0 " alt="" />
        </div>
  )
}

export default Header;
import'./sidebar.css';

function Sidebar() {
  return (
    <div className='sidebar'>
    <div className="sidebarItem">
        <span className="sidebarrTitle">ABOUT US</span>
        <img 
        className='sidebarImg'
        src="https://www.bing.com/th?id=OIP.tueggIDE0HiKBikgq2_J-QHaFj&w=288&h=216&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2 " alt="pic" />
        <p>
            Lorem ipsum dolor sit amet consectetur,
             adipisicing elit. Mollitia, molestias corrupti voluptas maiores ipsam
              non aliquam
        </p>
    </div>
    <div className="sidebarItem">
        <span className="sidebarTitle">CATEGORIES</span>
        <ul className="sidebarList">
            <li className="sidebarListItem">Life</li>
            <li className="sidebarListItem">Music</li>
            <li className="sidebarListItem">Style</li>
            <li className="sidebarListItem">Sport</li>
            <li className="sidebarListItem">Tech</li>
            <li className="sidebarListItem">LCinema</li>
        </ul>
    </div>
    <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW US</span>
        <div className="sidebarSocial">
    <i className="sidebarIcon fa-brands fa-square-facebook"></i>
    <i className="sidebarIcon   fa-brands fa-square-twitter"></i>
    <i className="sidebarIcon  fa-brands fa-pinterest"></i>
    <i className="sidebarIcon  fa-brands fa-square-instagram"></i>
        </div>
    </div>
    </div>
  )
}

export default Sidebar
import './singlepost.css';

function SinglePost() {
  return (
    <div className='singlepost'>
        <div className="singlepostWrapper">
          <img src="https://th.bing.com/th/id/R.712037bab8634a63b94c4cd1d22dc141?rik=3lotRj1zGK%2b0ag&riu=http%3a%2f%2fwww.pngall.com%2fwp-content%2fuploads%2f2%2fUpload-Transparent.png&ehk=1NW1RvOIMnDL%2bneLkwtLBWcJFFxE3uETUzRpfFe7RyA%3d&risl=&pid=ImgRaw&r=0 " 
          alt="" className="singlepostImg" />  
       <h1 className="singlePostTitle">
        Lorem ipsun dolor sit amet
        <div className="singlePostEdit">
        <i className="singlePostIcon fa-solid fa-pen-to-square"></i>
        <i className="singlePostIcon fa-solid fa-trash-can"></i>
        </div>
       </h1>
       <div className="singlePostInfo">
        <span className='singlePostAuthor'>Author: <b>Jafar</b></span>
        <span className='singlePostDate'>1 hour ago</span>
        </div>
    
        <p className='singlePostDesc'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi ducimus eos,
             dolore itaque sint, quia impedit ab cum eaque laborum voluptas natus
              dicta expedita officia quod quos doloremque, fuga voluptatibus.
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi ducimus eos,
             dolore itaque sint, quia impedit ab cum eaque laborum voluptas natus
              dicta expedita officia quod quos doloremque, fuga voluptatibus.
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi ducimus eos,
             dolore itaque sint, quia impedit ab cum eaque laborum voluptas natus
              dicta expedita officia quod quos doloremque, fuga voluptatibus.</p>
         </div>
        </div>
  )
}

export default SinglePost
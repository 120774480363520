import { Link } from 'react-router-dom';
import './topbar.css';




function Topbar() {
  const user=false;
  return (
    <div className='top'>
    <div className='topLeft'>
    <i className="topIcon fa-brands fa-square-facebook"></i>
    <i className="topIcon   fa-brands fa-square-twitter"></i>
    <i className="topIcon  fa-brands fa-pinterest"></i>
    <i className="topIcon  fa-brands fa-square-instagram"></i>
    </div>
    <div className='topCenter'>
        <ul className="topList">
         <li className='topListItem'> 
         <Link className='link'  to='/' >HOME</Link>
         </li>
            <li className="topListItem"><Link className='link' to='/Sidebar' >ABOUT</Link></li>
            <li className="topListItem"><Link className='link' to='/Contact' >CONTACT</Link></li>
            <li className="topListItem"><Link className='link' to='/Write' >WRITE</Link></li>
            <li className="topListItem"><Link className='link' to='/' ></Link></li>
            <li className="topListItem">
            {user && 'LOGOUT'}
            </li>
        </ul>
    </div>
    <div className='topRight'>
      {
        user ? (
          <img
          className="topImg" 
          src=" https://scontent.fabv3-1.fna.fbcdn.net/v/t1.6435-9/114890919_921750128322287_5150976027282135174_n.jpg?stp=dst-jpg_p526x296&_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGkYEvo9pEZm7t0fY6kWjvcCDWoZ9yXjXgINahn3JeNeKmSCZJPRoayIi6ClDAMBtq7EiP0xr9rHbriiOVDaafh&_nc_ohc=q3ZFKps5gcgAX-A64Yo&_nc_ht=scontent.fabv3-1.fna&oh=00_AT-gcozYFh0AVeVN2rATyx6efNpbZy5hm_YEwJSx2nCwsQ&oe=632DD0E1" alt="" />
      

        ) :(
          <ul className='topList'>
            <li className='topListItem'>
          <Link className='link' to='/login' >
            LOGIN
            </Link>
            </li>
            <li className='topListItem'>
          <Link className='link' to='/register' >
            REGISTER
            </Link>
            </li>
          </ul>
        )
      }
        
 <i className="topSearchIcon fa-brands fa-searchengin"></i>
    </div>
    </div>
  )
}

export default Topbar
import'./post.css';

function Post() {
  return (
    <div className='post'>
<img 
className='postImg'
src="https://www.agovtjobs.in/images/08042021042621.png"
alt="" />
<div className="postInfo">
   <div className="postCats">
    <span className="postCats">Music</span>
    <span className="postCats">Life</span>
    </div>    
    <span className="postTitle">Lorem ipsun dolor sit amet</span> 
    <hr/>
    <span className="postDate">1 hour ago</span>
    </div>
    <p className='postDesc'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem id 
        aspernatur corrupti provident aliquid in consequatur? Autem 
        laudantium, quam eius amet quas, ipsa
         perferendis expedita ducimus repudiandae a debitis asperiores!
         Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem id 
        aspernatur corrupti provident aliquid in consequatur? Autem 
        laudantium, quam eius amet quas, ipsa
         perferendis expedita ducimus repudiandae a debitis asperiores!
         Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem id 
        aspernatur corrupti provident aliquid in consequatur? Autem 
        laudantium, quam eius amet quas, ipsa
         perferendis expedita ducimus repudiandae a debitis asperiores!
         </p>
    </div>

  )
}

export default Post
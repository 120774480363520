import Sidebar from '../../components/sidebar/Sidebar';
import './settings.css';

function Settings() {
  return (
    <div className='settings'>
        <div className="settingsWrapper">
            <div className="settingsTitle">
                <span className="settingsUpdateTitle">Update Your Account</span>
                <span className="settingsDeleteTitle">Delete Account</span>
            </div>
            <form className="settingsForm">
              <label >Profile Picture</label>
              <div className="settingsPP">
                 <img src="https://scontent.fabv3-1.fna.fbcdn.net/v/t1.6435-9/114890919_921750128322287_5150976027282135174_n.jpg?stp=dst-jpg_p526x296&_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGkYEvo9pEZm7t0fY6kWjvcCDWoZ9yXjXgINahn3JeNeKmSCZJPRoayIi6ClDAMBtq7EiP0xr9rHbriiOVDaafh&_nc_ohc=q3ZFKps5gcgAX-A64Yo&_nc_ht=scontent.fabv3-1.fna&oh=00_AT-gcozYFh0AVeVN2rATyx6efNpbZy5hm_YEwJSx2nCwsQ&oe=632DD0E1" alt="man" />
                  <label htmlFor='fileInput'>
                  <i className="settingsPPIcon fa-solid fa-circle-user"></i>
                  </label>
                  <input type="file" id='fileInput' style={{display:'none'}}/>
              </div>
              <label >Username</label>
              <input type="text" placeholder='Lucidize -JAD'/>
              <label >Email</label>
              <input type="email" placeholder='jaafardazhi92@gmail.com'/>
              <label >Password</label>
              <input type="password" placeholder='******'/>
              <div className="botton settingsSubmit">Update</div>
              
            </form>
        </div>
        <Sidebar/>
        </div>
  )
}

export default Settings
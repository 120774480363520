import'./Posts.css';
import Post from '../Post/Post';

function Posts() {
  return (
    <div className='posts'>
       < Post/>
       < Post/>
       < Post/>
       < Post/>
       
       
        </div>
  )
}

export default Posts